<template>
  <div class="row">
    <div class="col-12 col-lg-6 form-group">
      <label>Company name <sup>Required</sup></label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i>
              <font-awesome-icon :icon="['fas', 'store-alt']" />
            </i>
          </span>
        </div>
        <input
          type="text"
          :class="['form-control form-control-lg', { 'is-invalid': $v.document.name.$error }]"
          :disabled="isDisabled"
          :readonly="isReadOnly"

          v-model.trim="$v.document.name.$model"
        >
      </div>
    </div>

    <div class="col-12 col-lg-6 form-group">
      <label>Company ID</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i>
              <font-awesome-icon :icon="['fas', 'hashtag']" />
            </i>
          </span>
        </div>
        <input
          type="text"
          :class="['form-control form-control-lg']"
          :disabled="isDisabled"
          :readonly="isReadOnly"

          v-model.trim="document.company_id"
        >
      </div>
    </div>

    <div class="col-12 form-group">
      <label>Location</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i>
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" />
            </i>
          </span>
        </div>
        <location-autocomplete
          ref="locationAutocomplete"
          v-model="form.location"

          class="form-control form-control-lg"

          :disabled="isDisabled"
          :readonly="isReadOnly"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Form from '@/mixins/form';
import { CompanyClass as Company } from '@vedicium/core-vue/build/modules/company';

import locationAutocomplete from '@/components/plugins/location-autocomplete/index.vue';

export default {
  mixins: [Form],
  components: {
    locationAutocomplete,
  },
  props: {
    document: {
      type: Company,
      default: null,
    },
  },
  validations: {
    document: {
      name: {
        required,
      },
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.location': function (value) {
      this.$set(this.document.location, 'place_id', value.place_id);
      this.$set(this.document.location, 'description', value.description);
    },
  },
  data () {
    return {
      form: {
        location: null,
      },
    };
  },

  mounted () {
    if (this.document && this.document.location && this.document.location.description) {
      this.$set(this.form, 'location', {
        place_id: this.document.location.place_id,
        description: this.document.location.description,
      });
    }
  },
};
</script>

<template>
  <div v-if="isMounted === false" class="row">
    <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
      <div class="loading-container">
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto w-100">
            <span class="float-left">Gathering data...</span>
            <span class="spinner spinner-primary ml-auto" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="row row-cols-1">
    <!-- begin::Company types -->
    <template v-if="companyTypes && companyTypes.length">
      <div v-for="(companyType, idx) in companyTypes" :key="`company.type.${idx}.${companyType}`" class="col">
        <label class="option cursor-pointer">
          <span class="option-control">
            <span class="radio">
              <input type="radio" v-model.trim="document.company_type" name="company_type" :value="companyType" checked="checked">
              <span></span>
            </span>
          </span>
          <span class="option-label">
            <span class="option-head">
              <span class="option-title">{{ $tu(`companies.type.${companyType}.name`) || companyType }}</span>
            </span>
            <span v-if="$te(`companies.type.${companyType}.description`)" class="option-body">{{ $t(`companies.type.${companyType}.description`) }}</span>
          </span>
        </label>
      </div>
    </template>
    <!-- end::Company types -->

    <!-- begin::Error alert -->
    <template v-else-if="isErrorActive">
      <error-translation-alert
        ref="errorTranslationAlert"
        :error="activeError"
      />
    </template>
    <!-- end::Error alert -->

    <!-- begin::Alert -->
    <template v-else>
      <div class="col-12 alert alert-custom alert-light-info my-0" role="alert">
        <div class="alert-icon">
          <i>
            <font-awesome-icon :icon="['fas', 'store-alt']" />
          </i>
        </div>
        <div class="alert-text">
          <span class="font-weight-bold d-block">No company types found</span>
          <span class="d-block">
            You don't have the permission to create a company type.
          </span>
        </div>
      </div>
    </template>
    <!-- end::Alert -->
  </div>
</template>

<script>
import Form from '@/mixins/form';
import { errorComponentMixin } from '@vedicium/core-vue';
import { CompanyClass as Company } from '@vedicium/core-vue/build/modules/company';
import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  mixins: [
    Form,
    errorComponentMixin,
  ],
  components: {
    errorTranslationAlert,
  },
  props: {
    document: {
      type: Company,
      default: null,
    },
  },
  data () {
    return {
      companyTypes: [],
    };
  },

  async mounted () {
    try {
      this.$set(this, 'companyTypes', await this.$ws.get(`${Company.uri}/types`));
    } catch (e) {
      this.$errors.handle(e, {
        ui_element: false,
      });

      console.error(e);
      return;
    } finally {
      this.$set(this, 'isMounted', true);
    }

    await this.$nextTick();

    if (!this.document.company_type && this.companyTypes) {
      // eslint-disable-next-line prefer-destructuring
      this.document.company_type = this.companyTypes[0];
    }
  },
};
</script>

<template>
  <div class="row">
    <div class="col form-group">
      <label>Email address <sup>Required</sup></label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i>
              <font-awesome-icon :icon="['fas', 'envelope']" />
            </i>
          </span>
        </div>
        <input
          type="text"
          :class="['form-control form-control-lg', { 'is-invalid': $v.document.administrator_email.$error }]"
          :disabled="isDisabled"
          :readonly="isReadOnly"

          v-model.trim="$v.document.administrator_email.$model"
        >
      </div>
      <span class="form-text text-muted">The email address will be used to send an invite to join this company to.</span>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import Form from '@/mixins/form';
import { CompanyClass as Company } from '@vedicium/core-vue/build/modules/company';

export default {
  mixins: [Form],
  props: {
    document: {
      type: Company,
      default: null,
    },
  },
  validations: {
    document: {
      administrator_email: {
        required,
        email,
      },
    },
  },
};
</script>
